@import "_sass/common";



/******* HOME *******/

/* HOME: RESETS */

#globalContent {
	padding: 0 !important;
}

/* HOME: COMMON */

.homeFeature .homeFeature-text h2,
.home-image .text h2 {
	margin-bottom: 20px;
	font-size: 26px;
	line-height: 26px;
	color: $color-blue;
}

.homeFeature .homeFeature-text p,
.home-image .text p {
	margin-bottom: 20px;
	font-size: 18px;
	line-height: 25px;
	color: $color-steel;
}

/* HOME: HERO */

%absolutePosition {
	position: absolute;
}

%transitionOpacity {
	will-change: opacity;
	@include transition (opacity 1s $easeOutExpo);
}

#home-hero {
	position: relative;
	display: block;
	padding-bottom: 80px;
	width: 100%;
	overflow: hidden;
	background: #000000;
}

#home-hero > .header {
	z-index: 10;
	left: 0;
	top: 25%;
	width: 100%;
	@extend %absolutePosition;
}

#home-hero > .header > .title {
	opacity: 0;
	margin: 0 auto 40px auto;
	display: block;
	height: 55px;
}

#home-hero.start > .header > .title {
	opacity: 1;
}

#home-hero > .header > .title > .layer {
	fill: rgba(255, 255, 255, 0) !important;
	will-change: stroke-dashoffset, fill;
	@include transition (fill 1s $easeOutExpo);
}

@for $i from 1 through 11 {

	#home-hero > .header > .title > .layer:nth-child(#{$i}) {
		@include transitionDelay (#{0.05s * $i});
	}

}

#home-hero.animate > .header > .title > .layer {
	fill: rgba(255, 255, 255, 1) !important;
}

#home-hero > .header > .buttons {
	opacity: 0;
	z-index: 10;
	margin: 0 auto 0 auto;
	width: 100%;
	font-size: 0;
	text-align: center;
	@include transitionDelay (0.25s + (0.2s * 1));
	@extend %transitionOpacity;
}

#home-hero.animate > .header > .buttons {
	opacity: 1;
}

#home-hero > .header > .buttons > .button:not(:hover) {
	background: transparentize(#000000, .8);
}

#home-hero > .header > .buttons > .button:hover {
	color: #000000 !important;
}

#home-hero > .header > .buttons > .button:nth-child(1) {
	margin-right: 40px;
}

#home-hero > .text {
	z-index: 10;
	left: 0;
	bottom: 20%;
	width: 100%;
	color: #FFFFFF;
	font-family: $fontStack-secondary;
	text-align: center;
	text-shadow: 0 1px 1px #000000;
	@include transitionDelay (0.25s + (0.2s * 2));
	@extend %absolutePosition;
}

#home-hero > .text > h1,
#home-hero > .text > p {
	opacity: 0;
	display: block;
	margin: 0 auto 0 auto;
	@extend %transitionOpacity;
}

#home-hero.animate > .text > h1,
#home-hero.animate > .text > p {
	opacity: 1;
}

#home-hero > .text > h1 {
	margin-bottom: 20px;
	width: 690px;
	line-height: $fontSize-extraLarge-lineHeight;
	@include transitionDelay (0.25s + (0.2s * 2));
}

#home-hero > .text > p {
	width: 600px;
	line-height: $fontSize-large-lineHeight;
	@include transitionDelay (0.25s + (0.2s * 3));
	@extend %text-large;
}

#home-hero > .text > p > a {
	position: relative;
	color: inherit;
	text-decoration: none;
}

#home-hero > .text > p > a:before {
	content: "";
	z-index: 0;
	position: absolute;
	left: 0;
	bottom: 0;
	display: block;
	width: 100%;
	height: 2px;
	background: #FFFFFF;
	@include transition (height .2s $easeOutExpo);
}

#home-hero > .text > p > a:hover {
	color: #000000;
	text-shadow: none;
}

#home-hero > .text > p > a:hover:before {
	height: $fontSize-large + ($fontSize-large-lineHeight - $fontSize-large);
}

#home-hero > .text > p > a > span {
	z-index: 10;
	position: relative;
}

$video-maxWidth: 1700px;

#home-hero > .video-wrapper {
	z-index: 0;
	position: relative;
	margin: 0 auto 0 auto;
	width: 100%;
	max-width: $video-maxWidth;
	height: 100%;
	min-height: 720px;
	background: url("../../img/home/home_hero_poster.png") bottom center no-repeat;
	background-size: cover;
}

#home-hero > .video-wrapper > .video-element {
	pointer-events: none;
	position: relative;
	display: block;
	margin: 0 auto 0 auto;
	width: 100%;
	min-width: 1280px;
	height: auto;
}

#home-hero > .download {
	z-index: 10;
	left: 0;
	bottom: 0;
	padding-top: 20px;
	width: 100%;
	height: 80px;
	font-size: 0;
	text-align: center;
	background: #161B44;
	@extend %absolutePosition;
}

#home-hero > .download > .download-button {
	display: inline-block;
}

#home-hero > .download > .download-button:first-child {
	margin-right: 20px;
}

#home-hero.animate > .download > .download-button {
	opacity: 1;
}

@media (min-width: $video-maxWidth) {

	#home-hero > .video-wrapper:before,
	#home-hero > .video-wrapper:after {
		content: "";
		z-index: 10;
		position: absolute;
		top: 0;
		display: block;
		width: 100px;
		height: 100%;
		background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
		background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
		background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#00000000", GradientType=1);
	}

	#home-hero > .video-wrapper:before {
		left: 0;
	}

	#home-hero > .video-wrapper:after {
		right: 0;
		@include transform (rotate(180deg));
	}

}

@media (max-width: 1280px) and (min-width: 881px) {

	#home-hero > .video-wrapper > .video-element {
		z-index: 0;
		position: absolute;
		left: 50%;
		top: 0;
		margin-left: -(1280px / 2);
	}

}

/* 2019 NEW HOME: HERO */
.wd1440{
	max-width: 1440px;
	margin: 0 auto;
}

#globalContent .section-1F{
	background-image: url("../../img/home/home-background1F.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 1024px;
	margin: 0 auto 0 auto;
}
.section-1F-center-box{
	 padding-top: 600px;
	 text-align: center
 }

.section-1F-center-box .container {
	border: 2px solid #ffffff;
	border-radius: 10px;
	width: 162px;
	height: 48px;
	color: #ffffff;
	font-family: Open Sans;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	cursor:pointer;
}

/* HOME: IMAGE */

.home-image {
	position: relative;
	margin: 0 auto 0 auto;
	padding: 0 20px 0 20px;
	width: 100%;
	background: center center no-repeat #000000;
}

.home-image > .wrapper {
	position: relative;
	margin: 0 auto 0 auto;
	width: 100%;
	max-width: 1000px;
	height: 100%;
}

@media (max-width: 1000px) {

	.home-image {
		background-position: left center;
	}

}

.home-image .text {
	position: absolute;
	left: 0;
	width: 350px;
}

#home-eWallet {
	height: 550px;
	background-image: url("../../img/home/home_eWallet.png");
}

#home-taxi {
	height: 520px;
}

body.en #home-taxi {
	background-image: url("../../img/home/home_taxi_en.png");
}

body.hk #home-taxi {
	background-image: url("../../img/home/home_taxi_hk.png");
}

body.cn #home-taxi {
	background-image: url("../../img/home/home_taxi_cn.png");
}

#home-eWallet .text {
	top: 35px;
}

#home-taxi .text {
	top: 65px;
}

/* FEATURE */

.homeFeature,
.homeFeature-columns {
	padding: 0 20px 0 20px;
	height: 520px;
}

.homeFeature > .wrapper {
	position: relative;
	margin: 0 auto 0 auto;
	width: 100%;
	max-width: 1000px;
	height: 100%;
}

.homeFeature .homeFeature-image {
	z-index: 0;
	position: absolute;
}

.homeFeature .homeFeature-text {
	z-index: 10;
	position: absolute;
	left: 0;
	width: 100%;
}

.homeFeature-columns:after {
	@include clearFix;
}

.homeFeature-columns .homeFeature {
	float: left;
	padding: 0;
	width: 50%;
}

.homeFeature-columns:before {
	content: "";
	position: absolute;
	right: 0;
	display: block;
	width: 50%;
	height: 520px;
	background: $color-backgroundAltDark;
}

.homeFeature-columns {
	margin: 0 auto 0 auto;
	max-width: 1040px;
}

.homeFeature.inline .homeFeature-text,
.homeFeature.inline .homeFeature-image {
	position: static;
}

.homeFeature.inline .homeFeature-text {
	max-width: none;
	text-align: center;
}

.homeFeature.inline .homeFeature-text .globalButton,
.homeFeature.inline .homeFeature-image {
	margin: 0 auto 0 auto;
}

#home-billPayment,
#home-platforms {
	border-bottom: 2px solid $color-backgroundAltDark;
}

#home-p2p {
	background: $color-backgroundAlt;
}

#home-p2p .homeFeature-text {
	top: 160px;
	padding-right: 500px;
}

#home-p2p .homeFeature-image {
	right: 0;
	bottom: 0;
}

#home-cashWithdrawal .homeFeature-image,
#home-transferMoney .homeFeature-image {
	bottom: 60px;
}

#home-cashWithdrawal .homeFeature-image {
	right: 25px;
}

#home-transferMoney .homeFeature-image {
	left: 25px;
}

#home-cashWithdrawal .homeFeature-text,
#home-transferMoney .homeFeature-text {
	top: 60px;
}

#home-cashWithdrawal .homeFeature-text {
	padding-right: 280px;
}

#home-transferMoney .homeFeature-text {
	padding-left: 280px;
}

#home-transferMoney .homeFeature-text {
	left: auto;
	right: 0;
	text-align: right;
}

#home-transferMoney .homeFeature-text .globalButton {
	margin-left: auto;
}

#home-billPayment .homeFeature-image {
	right: 0;
	top: 40px;
}

#home-billPayment .homeFeature-text {
	top: 60px;
	padding-right: 650px;
}

#home-platforms,
#home-lifestyle {
	position: relative;
	z-index: 10;
	height: auto;
}

#home-platforms > .wrapper,
#home-lifestyle > .wrapper {
	padding: 0 20px 0 20px;
}

#home-platforms .homeFeature-text,
#home-lifestyle .homeFeature-text {
	padding-top: 60px;
	margin: 0 auto 60px auto;
	max-width: 600px;
}

#home-platforms {
	padding-bottom: 20px;
	background: $color-backgroundAlt;
}

#home-platforms .platforms {
	list-style: none;
	font-size: 0;
	text-align: center;
}

#home-platforms .platforms li {
	margin-right: 20px;
	display: inline-block;
	font-size: 18px;
	line-height: 18px;
}

#home-platforms .platforms li:last-child {
	margin: 0;
}

#home-platforms .platforms li .image {
	margin: 0 auto 20px auto;
	width: 100%;
	height: auto;
}

#home-platforms .platforms li.store .image {
	max-width: 240px;
}

#home-platforms .platforms li.website .image {
	max-width: 390px;
}

#home-platforms .platforms li.app .image {
	max-width: 100px;
}

#home-lifestyle .homeFeature-text .dots {
	margin: 0 auto 20px auto;
	padding: 0 20px 0 20px;
	display: inline-table;
	height: 40px;
	font-size: 22px;
	line-height: 22px;
	color: #FFFFFF;
	border-radius: 40px;
	background: $color-blue;
}

#home-lifestyle .homeFeature-text .dots > .wrapper {
	@include tableCell;
}

#home-lifestyle .homeFeature-image {
	z-index: 10;
}

#home-lifestyle:after {
	content: "";
	z-index: 0;
	position: absolute;
	right: 0;
	bottom: 0;
	display: block;
	width: 459px;
	height: 133px;
	background: url("../../img/home/home_footer.png") center center no-repeat transparent;
}



/******* RESPONSIVE *******/

@media (max-width: 940px) {

	.homeFeature-columns {
		position: relative;
		padding: 0;
		height: auto;
	}

	.homeFeature-columns .homeFeature {
		float: none;
		padding: 0 20px 0 20px;
		width: 100%;
		height: 500px;
	}

	.homeFeature-columns:before {
		content: none;
		display: none;
	}

	#home-cashWithdrawal .homeFeature-text {
		padding-right: 400px;
	}

	#home-transferMoney {
		background: $color-backgroundAltDark;
	}

	#home-transferMoney .homeFeature-text {
		padding-left: 400px;
	}

	#home-cashWithdrawal .homeFeature-image,
	#home-transferMoney .homeFeature-image {
		top: 60px;
	}

}

@media (max-width: $maxWidth) {

	/* HOME: HERO */

	#home-hero {
		height: 600px; // Backwards compatible for browsers that don't support 'vh' units
		height: 100vh;
		min-height: 550px;
		max-height: 750px;
	}

	#home-hero > .header {
		top: 100px;
		padding: 0 40px 0 40px;
	}

	#home-hero > .header > .title {
		margin-bottom: 20px;
		width: 100%;
		height: auto;
	}

	#home-hero > .header > .title.title-en {
		max-width: 400px;
	}

	#home-hero > .header > .title.title-hk,
	#home-hero > .header > .title.title-cn {
		max-width: 250px;
	}

	#home-hero > .header > .buttons > .button {
		margin: 0 auto 0 auto !important;
		display: table;
		width: 100%;
		max-width: 200px;
	}

	#home-hero > .header > .buttons > .button:nth-child(1) {
		margin-bottom: 20px !important;
	}

	#home-hero > .header > .buttons > .button:nth-child(2) {
		clear: both;
	}

	#home-hero > .text {
		bottom: 20%;
		padding: 0 20px 0 20px;
	}

	#home-hero > .text > h1,
	#home-hero > .text > p {
		width: 100%;
		max-width: 400px;
	}

	#home-hero > .text > h1 {
		margin-bottom: 20px;
		font-size: 18px;
		line-height: 24px;
	}

	#home-hero > .text > p {
		font-size: 14px;
		line-height: 20px;
	}

	#home-hero > .text > p > a {
		text-decoration: underline;
	}

	#home-hero > .text > p > a:before {
		content: none;
		display: none;
	}

	#home-hero > .text > p > a:hover {
		color: inherit;
		text-shadow: inherit;
	}

	$video-maxWidth: 1700px;

	#home-hero > .video-wrapper {
		min-height: 0;
		background-image: url("../../img/home/home_hero_mobile_background.png");
	}

	#home-hero > .video-wrapper > .video-element {
		display: none;
	}

	#home-billPayment {
		padding-top: 40px;
		padding-bottom: 40px;
		height: auto;
	}

	#home-billPayment .homeFeature-image,
	#home-billPayment .homeFeature-text {
		position: static;
		margin: 0 auto 0 auto;
		width: 100%;
	}

	#home-billPayment .homeFeature-image {
		margin-bottom: 20px;
		max-width: 627px;
		height: auto;
	}

	#home-billPayment .homeFeature-text {
		padding-right: 0;
		text-align: center;
	}

	#home-billPayment .homeFeature-text .globalButton {
		margin: 0 auto 0 auto;
	}

	#home-platforms .platforms li {
		display: block;
		margin-bottom: 20px;
		margin-right: 0;
		font-size: 14px;
		line-height: 14px;
	}

	/* 2019 NEW HOME: HERO */
	.section-1F-center-box {
		padding-top: 450px;
	}
}

@media (max-width: 770px) {

	.homeFeature .homeFeature-text h2,
	.home-image .text h2 {
		margin-bottom: 10px;
		font-size: 18px;
		line-height: 18px;
	}

	.homeFeature .homeFeature-text p,
	.home-image .text p {
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 20px;
	}

	.homeFeature {
		padding: 40px 20px 40px 20px !important;
		height: auto !important;
	}

	.homeFeature-columns {
		padding: 0;
	}

	.homeFeature .homeFeature-text,
	.homeFeature .homeFeature-image {
		position: static;
		margin: 0 auto 0 auto;
	}

	.homeFeature .homeFeature-text {
		padding: 0 !important;
		text-align: center;
	}

	.homeFeature .homeFeature-text .globalButton {
		margin: 0 auto 0 auto;
	}

	.homeFeature .homeFeature-image {
		width: 100%;
		height: auto;
	}

	.home-image {
		padding: 0;
		height: 170px !important;
		background-position: center center;
		background-size: contain;
	}

	.home-image > .wrapper {
		display: table;
		padding: 10px;
		background: rgba(0, 0, 0, .5);
	}

	.home-image .text {
		@include tableCell;
		position: static;
		width: 100%;
		text-align: center;
	}

	.home-image .text p {
		color: #FFFFFF;
	}

	.home-image .text .globalButton {
		margin: 0 auto 0 auto;
	}

	#home-p2p {
		padding-bottom: 0 !important;
	}

	#home-p2p .homeFeature-image {
		margin-top: 40px;
		max-width: 468px;
	}

	#home-cashWithdrawal .homeFeature-image {
		margin-bottom: 40px;
		max-width: 317px;
	}

	#home-transferMoney .homeFeature-image {
		margin-bottom: 40px;
		max-width: 248px;
	}

	#home-transferMoney .homeFeature-text {
		text-align: center;
	}

	#home-platforms .homeFeature-text,
	#home-lifestyle .homeFeature-text {
		margin-bottom: 20px;
	}

	#home-lifestyle {
		padding-bottom: 0 !important;
	}

	#home-lifestyle .homeFeature-text .dots {
		margin-bottom: 10px;
		padding: 0;
		font-size: 16px;
		line-height: 16px;
		border-radius: 20px;
	}

	#home-lifestyle .homeFeature-text .dots > .wrapper {
		padding: 10px 20px 10px 20px;
	}

	#home-lifestyle .homeFeature-text .dots > .wrapper > span {
		display: block;
		opacity: 0;
		pointer-events: none;
		height: 5px;
	}

	#home-lifestyle:after {
		content: none;
		display: none;
	}

	/* 2019 NEW HOME: HERO */
	#globalContent .section-1F{
		background-image: url("../../img/home/home-background1F-web.jpg");
	}
	.wd1440{
		max-width: 375px;
	}
	.section-1F-center-box	.container{
		width: 137px;
		height: 40px;
		font-size: 14px;
	}
}

@media(max-width:$maxWidth){
	#home-hero>.header>.staticTitleImg{
		opacity:1;
		width:100%;
	}
	#home-hero>.header>.buttons{
		opacity:1;
	}
}

.trans-fadeout{	
	transition: opacity 2s ease-in-out; 
}
.staticTitleImg{
 margin:auto;
 opacity:0;
}

